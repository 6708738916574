.postmodal {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    z-index: 9999;
    width: 500px;
    max-width: 90%;
    padding: 20px;
    box-sizing: border-box;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9998;
}

.btnBox {
    margin-top: 20px;
    display: flex;
    justify-content: right;
}
